import React, { useMemo } from 'react'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer'
import { BLOCKS, Block as RichtextBlock } from '@contentful/rich-text-types'
import { Asset as CtfAsset } from '@cerifi/contentful/__generated/graphql.types'

import { cn } from '../../utils/cn'
import { OmitRecursive } from '../../utils/ctf-utils'

interface Block extends RichtextBlock {
  __typename: string
  sys: { id: string }
}

type Asset = OmitRecursive<CtfAsset, '__typename'>

interface RichtextProps {
  json: any
  links?: {
    entries?: {
      block?: any
      inline?: any
      hyperlink?: any
    } | null
    assets?: {
      block?: any
    } | null
  } | null
  className?: string
  style?: any
}

const ctfBaseRichTextOptions = ({ links }: RichtextProps): Options => ({
  renderText: (text) => {
    return text.split('\n').reduce((children: any[], textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment]
    }, [])
  },
  renderNode: {
    [BLOCKS.HEADING_1]: (node, next) => {
      return (
        <div className="sm:text-4xl text-2xl title-font font-medium">
          {next}
        </div>
      )
    },
    [BLOCKS.HEADING_2]: (node, next) => {
      return (
        <div className="sm:text-3xl text-2xl title-font font-medium">
          {next}
        </div>
      )
    },
    [BLOCKS.HEADING_3]: (node, next) => {
      return (
        <div className="sm:text-2xl text-xl title-font font-medium">{next}</div>
      )
    },
    [BLOCKS.HEADING_4]: (node, next) => {
      return <div className="sm:text-xl text-lg">{next}</div>
    },
    [BLOCKS.HEADING_5]: (node, next) => {
      return <div className="sm:text-lg">{next}</div>
    },
    [BLOCKS.HEADING_6]: (node, next) => {
      return <div>{next}</div>
    },
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const image = links?.assets?.block.find(
        (x: any) => x.sys.id === node?.data?.target?.sys?.id
      )

      return (
        <img
          style={{
            display: 'inline',
          }}
          src={image?.url}
          alt={image?.description || image?.title}
        />
      )
    },
    'entry-hyperlink': (node) => {
      const entry = links?.entries?.hyperlink?.find(
        (item: any) => item?.sys?.id === node.data.target.sys.id
      )

      if (!entry) return <></>

      let slug = ''
      if (entry.__typename === 'Product') {
        slug = `/products/${entry.sku}`
      } else if (entry.__typename === 'ArticlePage') {
        slug = `/article/${entry.slug}`
      } else if (entry.__typename === 'PageArticleIndex') {
        slug = `/articles/${entry.slug}`
      } else if (entry.__typename === 'PageAffiliate') {
        slug = `/university/${entry.slug}`
      } else {
        slug = entry.slug
      }

      return (
        <a href={slug} className="underline">
          {(node.content[0] as any).value}
        </a>
      )
    },
  },
})

const RichText = React.forwardRef<HTMLDivElement, RichtextProps>(
  ({ json, links, className, ...props }, ref) => {
    const options = ctfBaseRichTextOptions({ json, links })

    const isWhitespace = useMemo(
      () => !documentToPlainTextString(json).trim(),
      [json]
    )

    if (isWhitespace) {
      return null
    }

    return (
      <div {...props} ref={ref} className={cn(className, 'rich-text')}>
        {documentToReactComponents(json, options)}
      </div>
    )
  }
)

RichText.displayName = 'RichText'

export { RichText }
