'use client'

import { useMemo } from 'react'
import { ContentfulLivePreview } from '@contentful/live-preview'
import { RichText } from '@cerifi/ui/cms/helper-components/rich-text'
import { Container } from '@cerifi/ui/components/container'

import { Form as FormModel } from '../../../__generated/graphql.types'
import { FORMS } from './form.model'

export default function FormCms({ content }: { content: FormModel }) {
  const formStr = useMemo(() => {
    switch (content.form) {
      case 'Dalton - Exam Result Report':
        return FORMS.EXAM_RESULT
      case 'Dalton - TDR Material Request':
        return FORMS.TDR_MATERIAL_REQUEST
      case 'Dalton - Guarantee to Pass Review Material':
        return FORMS.GUARANTEE_TO_PASS_MATERIAL_REQUEST
      case 'Dalton - TAC Guarantee To Pass Review Material':
        return FORMS.TAC_MATERIAL_REQUEST
      case 'Dalton - TAC Dalton Review Material Request':
        return FORMS.TAC_DALTON_REVIEW_MATERIAL_REQUEST
      case 'Dalton - Contact':
        return FORMS.CONTACT
      case 'Dalton - Edward Jones Request':
        return FORMS.EDWARD_JONES_REQUEST
      default:
        console.error('UNKOWN FORM TYPE: ' + content.form)
        return ''
    }
  }, [content.form])
  return (
    <Container id={content.sys.id} className="py-16 scroll-mt-25">
      {content.title && (
        <h2
          aria-level={(content as any).isH1 ? 1 : 2}
          className=" mx-auto max-w-[600px]"
          {...ContentfulLivePreview.getProps({
            entryId: content.sys.id,
            fieldId: 'title',
            locale: 'en-US',
          })}
        >
          <RichText
            json={content.title?.json}
            links={content.title?.links}
            data-testid="embed-title"
            className="text-center font-sans text-xl font-normal leading-[2.4rem] sm:text-2xl"
          />
        </h2>
      )}

      {content.description && (
        <div
          className="mx-auto max-w-[800px] py-6 text-center"
          {...ContentfulLivePreview.getProps({
            entryId: content.sys.id,
            fieldId: 'description',
            locale: 'en-US',
          })}
        >
          <RichText
            json={content.description.json}
            links={content.description.links}
            data-testid="embed-description"
          />
        </div>
      )}

      <div
        dangerouslySetInnerHTML={{
          __html: formStr,
        }}
        suppressHydrationWarning
      />
    </Container>
  )
}
