'use client'

import {
  ArticlePageBelowContentCollection,
  ArticlePageContentCollection,
  PageAffiliateContentCollection,
  PageArticleIndexContentCollection,
  PageCategoryContentCollection,
  PageContentCollection,
  PagePackageCollectionContentCollection,
  ProductProductDetailContentCollection,
  TabItemBodyCollection,
} from '@cerifi/contentful/__generated/graphql.types'
import { ContentBlock } from '@cerifi/contentful/components/content-block/content-block'

import '@contentful/live-preview/style.css'

const Content = ({
  contentCollection,
}: {
  contentCollection:
    | PageContentCollection
    | ArticlePageContentCollection
    | ArticlePageBelowContentCollection
    | PageArticleIndexContentCollection
    | PagePackageCollectionContentCollection
    | PageCategoryContentCollection
    | TabItemBodyCollection
    | PageAffiliateContentCollection
    | ProductProductDetailContentCollection
}) => {
  if (!contentCollection) return null

  return <ContentBlock contentCollection={contentCollection} />
}
export default Content
