'use client'

import { Fragment } from 'react'
import dynamic from 'next/dynamic'
import { useContentfulLiveUpdates } from '@contentful/live-preview/react'

import {
  ArticlePageBelowContentCollection,
  ArticlePageContentCollection,
  PageAffiliateContentCollection,
  PageArticleIndexContentCollection,
  PageCategoryContentCollection,
  PageContentCollection,
  PagePackageCollectionContentCollection,
  ProductProductDetailContentCollection,
  TabItemBodyCollection,
} from '../../__generated/graphql.types'
import FormCms from '../content-types/form/form.cms'

const DividerCms = dynamic(() => import('@cerifi/ui/cms/divider/divider.cms'))
const Embed = dynamic(() => import('../content-types/embed/embed'))
const FeaturedProducts = dynamic(
  () => import('../content-types/featured-products')
)
const Hero = dynamic(() => import('../content-types/hero/hero'))
const Image = dynamic(() => import('../content-types/image/image'))
const InfoHighlightBlock = dynamic(
  () => import('../content-types/info-highlight-block/info-highlight-block')
)
const LayoutList = dynamic(
  () => import('../content-types/layout-list/layout-list')
)
const LayoutMosaic = dynamic(
  () => import('../content-types/layout-mosaic/layout-mosaic')
)
const LayoutPersonList = dynamic(
  () => import('../content-types/layout-person-list/layout-person-list')
)
const LayoutTabs = dynamic(
  () => import('../content-types/layout-tabs/layout-tabs')
)
const PageNav = dynamic(() => import('../content-types/page-nav/page-nav'))
const ProductComparison = dynamic(
  () => import('../content-types/product-comparison/product-comparison')
)
const RichText = dynamic(() => import('../content-types/rich-text/rich-text'))
const StatsBanner = dynamic(
  () => import('../content-types/stats-banner/stats-banner')
)
const Stepper = dynamic(() => import('../content-types/stepper/stepper'))
const TextCardColumn = dynamic(
  () => import('../content-types/text-card-column/text-card-column')
)
const LayoutLogoList = dynamic(
  () => import('../layout-logo-list.tsx/layout-logo-list')
)

const Accordion = dynamic(() => import('../content-types/accordion/accordion'))
const BidirectionalScroller = dynamic(
  () =>
    import('../content-types/bi-directional-scroller/bi-directional-scroller')
)
const BrandVerticalOverview = dynamic(
  () =>
    import('../content-types/brand-vertical-overview/brand-vertical-overview')
)
const Cta = dynamic(() => import('../content-types/cta/cta'))
const Disclaimer = dynamic(
  () => import('../content-types/disclaimer-dialog/disclaimer-dialog')
)

const ProductDetail = dynamic(() => import('../content-types/product-detail'))

/** Renders a list of content types */
export const ContentBlock = (props: {
  contentCollection:
    | PageContentCollection
    | ArticlePageContentCollection
    | ArticlePageBelowContentCollection
    | PageArticleIndexContentCollection
    | PagePackageCollectionContentCollection
    | PageCategoryContentCollection
    | TabItemBodyCollection
    | PageAffiliateContentCollection
    | ProductProductDetailContentCollection
}) => {
  if (!props.contentCollection?.items) {
    return null
  }

  /** render one content slice */
  const Block = (item: any) => {
    const content = useContentfulLiveUpdates(item)
    if (!content) {
      console.warn('null content type received in group')
      return null
    }
    if (!content.__typename) {
      console.warn('item does not have typename? wrong datatype')
      return null
    }

    switch (content?.__typename) {
      case 'ComponentHero':
        return <Hero content={content} />
      case 'ContentTypeRichText':
        return <RichText content={content} />
      case 'Image':
        // eslint-disable-next-line jsx-a11y/alt-text
        return <Image content={content} />
      case 'LayoutList':
        return <LayoutList content={content} />
      case 'LayoutLogoList':
        return <LayoutLogoList content={content} />
      case 'LayoutMosaic':
        return <LayoutMosaic content={content} />
      case 'LayoutTab':
        return <LayoutTabs content={content} />
      case 'LayoutPersonList':
        return <LayoutPersonList content={content} />
      case 'ComponentEmbed':
        return <Embed content={content} />
      case 'ComponentAccordion':
        return <Accordion content={content} />
      case 'ComponentStatsBanner':
        return <StatsBanner content={content} />
      case 'ComponentStepper':
        return <Stepper content={content} />
      case 'ComponentCta':
        return <Cta content={content} />
      case 'ComponentTextCardColumn':
        return <TextCardColumn content={content} />
      case 'InfoHighlightBlock':
        return <InfoHighlightBlock content={content} />
      case 'PageNavComponent':
        return <PageNav content={content} />
      case 'ProductComparison':
        return <ProductComparison content={content} />
      case 'FeaturedProducts':
        return <FeaturedProducts content={content} />
      case 'ProductDetail':
        return <ProductDetail content={content} />
      case 'DisclaimerDialog':
        return <Disclaimer content={content} />
      case 'Divider':
        return <DividerCms />
      case 'BidirectionalScroller':
        return <BidirectionalScroller content={content} />
      case 'BrandVerticalOverview':
        return <BrandVerticalOverview content={content} />
      case 'Form':
        return <FormCms content={content} />
      default:
        console.warn('unknown content type: ' + content.__typename)
        return null
    }
  }

  return (
    <>
      {props.contentCollection.items.map(
        (item, i) =>
          item && (
            <Fragment key={`${item.sys?.id}-${i}`}>{Block(item)}</Fragment>
          )
      )}
    </>
  )
}
